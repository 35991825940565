import { Typography } from '@mui/material';
import { makeStyles, createStyles } from '@mui/styles';
import * as React from 'react';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: '50px',
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '10px',
    },
    message: {
      fontFamily: 'DancingScript',
      fontSize: 24,
    },
    link: {
      fontFamily: 'Belleza',
      fontSize: 24,
      color: theme.palette.secondary.contrastText,
    },
  }),
);

interface NotFoundProps {
  redirect?: boolean;
}

const NotFound: React.FC<NotFoundProps> = (props) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Typography className={classes.message}>
        Oops! This page does not exist.
      </Typography>
      <Link className={classes.link} to={'/'}>
        Return to Joyify Dance!
      </Link>
    </div>
  );
};

export default NotFound;
