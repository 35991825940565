import * as React from 'react';
import * as ReactDOM from 'react-dom/client';
import App from './components/App';

const el = document.getElementById("root");
if (!el) {
  throw new Error("React root element does not exist in the DOM.");
}
const root = ReactDOM.createRoot(el);
root.render(<App />);
