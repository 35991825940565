import * as React from 'react';
import {
  createBrowserRouter as createRouter,
  RouterProvider,
} from 'react-router-dom';

import Root from './Root';
import NotFound from './NotFound';
import Login from './Login';

const Home = React.lazy(() => import('./Home'));
const About = React.lazy(() => import('./About'));
const Contact = React.lazy(() => import('./Contact'));
const Media = React.lazy(() => import('./Media'));
const Weddings = React.lazy(() => import('./offerings/Weddings'));
const PrivateLessons = React.lazy(() => import('./offerings/PrivateLessons'));
const GroupClasses = React.lazy(() => import('./offerings/GroupClasses'));
const SpecialEvents = React.lazy(() => import('./offerings/SpecialEvents'));

// const router = createRouter(createRoutesFromElements(
//   <Route path="/" element={<Root />} errorElement={<NotFound />} >
//     <Route errorElement={<div>Oops! Not found...</div>} >
//       <Route index element={<Home />} />
//       <Route path="about" element={<About />} />
//       <Route path="media" element={<Media />} />
//       <Route path="contact" element={<Contact />} />
//       <Route path="tab" element={<Tap />} />
//       <Route path="*" element={<NotFound />} />
//     </Route>
//   </Route>
// ));

const router = createRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <NotFound />,
    children: [
      {
        errorElement: <div>404 Not Found</div>, // this one is never hit, so I added the '*' path at the end to catch non-matches.
        children: [
          {
            index: true,
            element: <Home />,
          },
          {
            path: 'about',
            element: <About />,
          },
          {
            path: 'media',
            element: <Media />,
          },
          {
            path: 'contact',
            element: <Contact />,
          },
          {
            path: 'login',
            element: <Login />,
          },
          {
            path: 'weddings',
            element: <Weddings />,
          },
          {
            path: 'private-lessons',
            element: <PrivateLessons />,
          },
          {
            path: 'group-classes',
            element: <GroupClasses />,
          },
          {
            path: 'special-events',
            element: <SpecialEvents />,
          },
          {
            path: '*',
            element: <NotFound />,
          },
        ],
      },
    ],
  },
]);

const AppRoutes = () => <RouterProvider router={router} />;

export default AppRoutes;
