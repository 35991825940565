import * as React from 'react';
import PageHeader from './PageHeader';
import HeroImage from '../assets/images/adult-art-ballerina-209948.jpg';

const Login: React.FC = () => {
  return (
    <>
      <PageHeader
        title="Joyify Dance"
        image={HeroImage}
        description="Spreading the joy of dance one step at a time"
      />
    </>
  );
};

export default Login;
