import * as React from 'react';
import { useTheme } from '@mui/styles';
import { Box } from '@mui/material';

interface BodyProps {}

const Body: React.FC<BodyProps & React.PropsWithChildren> = ({ children }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.default,
        minHeight: '100vh',
      }}
    >
      {children}
    </Box>
  );
};

export default Body;
