import * as React from 'react';
import { createStyles, makeStyles, useTheme } from '@mui/styles';
import {
  Drawer,
  AppBar,
  Toolbar,
  List,
  Typography,
  Divider,
  IconButton,
  ClickAwayListener,
  Box,
} from '@mui/material';
import {
  Menu as MenuIcon,
  ChevronLeft as ChevronLeftIcon,
  ChevronRight as ChevronRightIcon,
} from '@mui/icons-material';
import ListItemLink from './ListItemLink';
import { routes, Route } from '../routes';
import { useLocation } from 'react-router-dom';

const drawerWidth = 240;

const useStyles = makeStyles((theme) =>
  createStyles({
    appBar: {
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(['margin', 'width'], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginLeft: 12,
      marginRight: 20,
    },
    hide: {
      display: 'none',
    },
    drawer: {
      // width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: 'flex',
      alignItems: 'center',
      padding: '0 8px',
      ...theme.mixins.toolbar,
      justifyContent: 'flex-end',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3),
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      marginLeft: -drawerWidth,
    },
    contentShift: {
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    },
  }),
);

interface NavigationProps {
  [key: string]: any;
}

const Navigation: React.FC<NavigationProps> = (props) => {
  const [open, setOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    if (open) {
      setOpen(false);
    }
  };

  const location = useLocation();
  const currentPage = routes.get(location.pathname)?.name;
  const theme = useTheme();
  const classes = useStyles();

  const mainRoutes: Route[] = [];
  const offeringRoutes: Route[] = [];
  Array.from(routes.keys()).forEach((key: string) => {
    const route = routes.get(key) as Route;
    if (route.icon) {
      mainRoutes.push(route);
    } else {
      offeringRoutes.push(route);
    }
  });

  return (
    <ClickAwayListener onClickAway={handleDrawerClose}>
      <Box
        sx={{
          display: 'flex',
        }}
      >
        <AppBar
          position="fixed"
          // color="transparent"
          classes={{
            root: `${classes.appBar} ${open ? classes.appBarShift : ''}`,
          }}
        >
          <Toolbar disableGutters={!open}>
            <IconButton
              color="primary"
              aria-label="Open drawer"
              onClick={handleDrawerOpen}
              className={`${classes.menuButton} ${open ? classes.hide : ''}`}
              size="large"
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" color="textPrimary" noWrap>
              {currentPage}
            </Typography>
          </Toolbar>
        </AppBar>
        <div>
          <Drawer
            className={classes.drawer}
            variant="persistent"
            anchor="left"
            open={open}
            classes={{
              paper: classes.drawerPaper,
            }}
          >
            <div className={classes.drawerHeader}>
              <IconButton onClick={handleDrawerClose} size="large">
                {theme.direction === 'ltr' ? (
                  <ChevronLeftIcon />
                ) : (
                  <ChevronRightIcon />
                )}
              </IconButton>
            </div>
            <Divider />
            <nav onClick={handleDrawerClose}>
              <List>
                {mainRoutes.map((route) => {
                  const IconComponent = route.icon;
                  if (IconComponent) {
                    return (
                      <ListItemLink
                        key={route.name}
                        to={route.path}
                        text={route.name}
                        icon={<IconComponent />}
                      />
                    );
                  }
                })}
              </List>
              <Divider />
              <List>
                {offeringRoutes.map((route) => {
                  return (
                    <ListItemLink
                      key={route.name}
                      to={route.path}
                      text={route.name}
                    />
                  );
                })}
              </List>
            </nav>
            <Divider />
          </Drawer>
        </div>
      </Box>
    </ClickAwayListener>
  );
};

export default React.memo(Navigation);
