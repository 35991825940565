import * as React from 'react';
import { Outlet } from 'react-router-dom';
import Navigation from './Navigation';

const Root = () => {
  return (
    <>
      <Navigation />
      {/* Putting Suspense around Navigation is messing up the slider. */}
      <React.Suspense>
        {/* Render whichever child component matches the nested path. */}
        <Outlet />
      </React.Suspense>
    </>
  );
};

export default Root;
